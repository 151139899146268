<template>
  <div class="warning-container">
    <div class="typewriter-wrapper">
      <div class="typewriter" ref="typewriter">
        <p v-for="line in displayedLines" :key="line">{{ line }}</p>
      </div>
    </div>

    <!-- Next Button -->
    <button :disabled="!typingComplete" class="next-button" @click="showAgentModal">Next</button>

    <!-- Agent Name Entry Modal -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h2>Enter Agent Name</h2>
        <input v-model="agentName" type="text" placeholder="Secret Agent ________" />
        <button @click="enterOffice" class="action-button">Enter Office</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "WarningView",
  data() {
    return {
      lines: [
        "Welcome to the conspiracy trivia game — ",
        "We have Dossiers, and we have Questions.",
        "There are secrets lurking in the shadows, hidden hands",
        "holding the curtains. Nothing is quite what it seems.",
        "Much of the truth is hard to read.",
        "Exposing the man behind the curtain is not a task for",
        "the faint of heart. If you accept this mission, as an",
        "agent of the Community Intelligence Organization, press",
        "next to continue..."
      ],
      displayedLines: [],
      agentName: "",
      typingInterval: null,
      showModal: false,
      typingComplete: false,
    };
  },
  methods: {
    typeLine(line) {
      return new Promise((resolve) => {
        let currentText = "";
        const chars = line.split("");
        this.typingInterval = setInterval(() => {
          if (chars.length > 0) {
            currentText += chars.shift();
            this.displayedLines[this.displayedLines.length - 1] = currentText;
          } else {
            clearInterval(this.typingInterval);
            resolve();
          }
        }, 50);
      });
    },
    async typewriterEffect() {
      for (const line of this.lines) {
        this.displayedLines.push("");
        await this.typeLine(line);
      }
      this.typingComplete = true;
    },
    showAgentModal() {
      this.showModal = true;
    },
    async enterOffice() {
      if (this.agentName) {
        await this.$store.dispatch("auth/saveAgentName", this.agentName);
        this.$router.push("/welcome");
      } else {
        alert("Please enter an agent name.");
      }
    },
  },
  mounted() {
    this.typewriterEffect();
  },
};
</script>

<style scoped>
.warning-container {
  background: black url('@/assets/images/disclaimer.webp') no-repeat calc(50% + -15px) center;
  background-size: 200%; /* This will ensure the background fills the viewport */
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: green;
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  padding: 1rem;
  position: relative;
}

.typewriter-wrapper {
  width: 70%; /* Fit text within the "screen" area */
  max-width: 600px;
  height: 80vh; /* Occupy most of the visible area */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  text-align: center;
  padding: 1rem;
}

.typewriter {
  font-size: 1.2rem;
  line-height: 1.4;
  color: #32CD32;
  white-space: pre-wrap;
  text-align: left;
}

/* Button styles */
.next-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: rgb(0, 0, 0);
  color: white;
  border: 2px solid white; /* Thicker border */
  padding: 1rem 2rem; /* Increased padding for larger button */
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  font-size: 1.0rem; /* Increased font size */
}

.next-button:disabled {
  color: rgba(255, 255, 255, 0.5); /* Gray out text */
  border-color: rgba(255, 255, 255, 0.5); /* Gray out border */
  cursor: not-allowed;
  background-color: rgb(0, 0, 0);
}


.action-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal-content {
  background-color: black;
  padding: 2rem;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
  text-align: center;
  color: #32CD32;
  font-family: 'Courier New', Courier, monospace;
}

.modal-content h2 {
  margin-bottom: 1rem;
}

.modal-content input {
  margin-bottom: 1rem;
  padding: 0.75rem;
  font-size: 1rem;
  font-family: 'Courier New', Courier, monospace;
  border: none;
  border-radius: 4px;
  width: 90%;
  max-width: 300px;
}

/* Responsive adjustments */
@media (min-width: 1400px) {
  .typewriter-wrapper {
    width: 60%;
    height: 65vh;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .typewriter-wrapper {
    width: 70%;
    height: 70vh;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .typewriter-wrapper {
    width: 75%;
    height: 80vh;
  }
}

@media (max-width: 767px) {
  .typewriter-wrapper {
    width: 80%;
    height: 80vh;
  }

  .typewriter {
    font-size: .9rem;
  }
}
</style>
