<template>
  <div class="terms-container">
    <div class="terms-content">
      <h1>Terms of Use</h1>
      <p><strong>Last Updated:</strong> Oct 1, 2024</p>

      <p>Welcome to <em>‘Conspiracy Trivia’</em>, an online trivia fictional detective game ("the game"). The following terms cover the game and its developers and owners ("we," "us," or "our"). By accessing or playing this game, you agree to these Terms of Use ("Terms"). If you do not agree to these Terms, you must not use or engage with the game.</p>

      <h2><strong>1. No Liability for User Experience</strong></h2>
      <p>By playing or interacting with <em>‘Conspiracy Trivia’</em>, you agree that you are solely responsible for your experience within the game. We disclaim all liability and responsibility for any outcome, direct or indirect, resulting from your engagement with the game. This includes, but is not limited to, any emotional, psychological, or other effects associated with gameplay.</p>

      <h2><strong>2. Content Warning</strong></h2>
      <p><em>‘Conspiracy Trivia’</em> contains topics and content that may be inappropriate or disturbing to some players. These include, but are not limited to, adult themes, explicit language, sensitive historical events, and other potentially controversial or unsettling content. By playing the game, you acknowledge that you do so at your own risk and discretion.</p>
      <p>Players under the age of 18, or under the legal age of independent adulthood in their jurisdiction, must obtain permission from a parent or legal guardian to play. We take no responsibility for any consequences arising from the failure to comply with this requirement.</p>

      <h2><strong>3. Right to Modify or Change the Game</strong></h2>
      <p>We reserve the right to modify or discontinue the game or any part of its functionality at any time, without notice and without liability. This includes the right to:</p>
      <ul>
        <li>Change, modify, or remove content or features.</li>
        <li>Change or remove user accounts and account functionality.</li>
        <li>Make updates, fixes, or alterations to gameplay, credits, or other features.</li>
      </ul>
      <p>Any such changes may occur without prior notification, and we will not be held responsible for any effect these changes have on user experience, saved data, or progress.</p>

      <h2><strong>4. No Guarantee of Access</strong></h2>
      <p>We make no guarantees regarding continuous access to <em>‘Conspiracy Trivia’</em> or user accounts. You acknowledge that your access to the game may be interrupted, restricted, or terminated at any time, with or without notice, and for any reason, including but not limited to server outages, maintenance, or the complete discontinuation of the game.</p>
      <p>In the event of such disruptions or terminations, you understand and accept that:</p>
      <ul>
        <li>User progress, in-game credits (including "sense"), and saved information may be lost without warning or recovery.</li>
        <li>We are not liable for any such losses, and no compensation or recourse will be provided.</li>
      </ul>

      <h2><strong>5. Accuracy and Reliability of Content</strong></h2>
      <p>While we strive to use good judgment in curating and presenting information within the game, we do not guarantee that any content or information is accurate, complete, up-to-date, or reliable. The game’s content is provided for entertainment purposes only, and any factual claims or statements made should not be taken as definitive or authoritative.</p>
      <p>By playing the game, you agree that you will not hold us responsible for any inaccuracies, errors, or omissions in the content, and you acknowledge that the information presented may be outdated or incorrect.</p>

      <h2><strong>6. Neutrality and No Implied Associations</strong></h2>
      <p>The content within the game, including questions, answers, and general game structure, does not imply or suggest any conclusions, assumptions, or associations about any person, place, group, or organization. The arrangement of questions and answers is for entertainment purposes only and is not based on any particular logic or factual relationship. We make no representations about the association between any entities or ideas that appear within the game.</p>

      <h2><strong>7. Changes to These Terms</strong></h2>
      <p>We reserve the right to modify these Terms at any time. Any changes will be posted on this page, and your continued use of the game after such changes will signify your acceptance of the revised Terms. You are encouraged to review these Terms periodically for any updates.</p>

      <h2><strong>8. Objections to Content</strong></h2>
      <p>If any person, group, organization, or other entity has an objection to any information or content within <em>‘Conspiracy Trivia’</em>, or believes that any information is false, misrepresentative, or contradicts credible sources, we encourage you to contact us at the contact info in the About Conspiracy Trivia page or at <strong>isaiah32mission@gmail.com</strong>.</p>
      <p>Please provide specific details of the issue along with substantial and credible proof of your claim. We take such concerns seriously and will promptly review any submitted information. If we find the content to be of reasonable concern, we will take appropriate action, including the potential removal of the content.</p>

      <h2><strong>9. Freedom of Speech and Expression</strong></h2>
      <p><em>‘Conspiracy Trivia’</em> embraces the principles of free speech, free expression, and freedom to organize. The game provides a platform for diverse perspectives, and we encourage thoughtful interaction. However, this does not imply that we endorse any particular viewpoints expressed within the content or by users, or any viewpoint implied by the content or its users.</p>

      <h2><strong>10. Entertainment and Educational Purposes Only</strong></h2>
      <p>The content in <em>‘Conspiracy Trivia’</em> is provided for entertainment and educational purposes only. While the game may reference real-world events, historical facts, or cultural phenomena, the primary goal is to engage and entertain players through trivia.</p>

      <h2><strong>11. Please Appreciate Your Experience of the Game</strong></h2>
      <p>Be Cool.</p>

      <h2><strong>12. Contact Us</strong></h2>
      <p>If you have any questions or concerns regarding these Terms or the game, please contact us at <strong>isaiah32mission@gmail.com</strong>.</p>
      <p>Enjoy playing <strong>Conspiracy Trivia™</strong>!</p>
    </div>

    <button class="next-button" @click="goToWarning">Back</button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'TermsOfUseView',
  methods: {
    goToWarning() {
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.terms-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  color: #333;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100vh; /* Fit within viewport */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.terms-content {
  flex: 1 1 auto;
  padding: 20px;
  overflow-y: auto;
  text-align: left; /* Ensures all text is left-aligned */
}

h1, h2, p, ul {
  text-align: left;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 0.5rem;
}

.next-button {
  width: 100%; /* Stretch button across the bottom */
  padding: 15px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 0 0 8px 8px; /* Rounded corners for button container bottom */
  cursor: pointer;
  text-align: center;
}

.next-button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .terms-content {
    padding: 10px;
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  .next-button {
    font-size: 0.9rem;
    padding: 12px;
  }
}

@media (max-width: 480px) {

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  .next-button {
    font-size: 0.85rem;
    padding: 10px;
  }
}
</style>
