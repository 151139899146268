/* eslint-disable */
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import firebaseApp from '../firebaseConfig';

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

// Register user and create Firestore user record
export const register = async (email, password) => {
  try {
    console.log("Attempting registration with email:", email);
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    console.log("Registration successful, user credential received:", userCredential);

    const user = userCredential.user;

    // Create Firestore record for the user
    console.log("Creating Firestore record for user:", user.uid);
    await setDoc(doc(db, 'users', user.uid), {
      firstLogin: true,
      agentName: '',
      createdAt: new Date(),
      sense: 500 // Initial value for new users
    });

    console.log("Registration complete.");
    return { user, firstLogin: true };
  } catch (error) {
    console.error("Registration Error:", error.message);
    throw new Error(`Registration failed: ${error.message}`);
  }
};

// Login user and fetch Firestore data
export const login = async (email, password) => {
  try {
    console.log("Attempting login with email:", email); // Log email
    const userCredential = await signInWithEmailAndPassword(auth, email, password);

    console.log("Login successful, user credential received:", userCredential);

    const user = userCredential.user;

    // Fetch user data from Firestore
    console.log("Fetching Firestore data for user:", user.uid);
    const userDoc = await getDoc(doc(db, 'users', user.uid));

    if (userDoc.exists()) {
      console.log("User data from Firestore:", userDoc.data());
      return { user, userData: userDoc.data() };
    } else {
      throw new Error('User data not found in Firestore');
    }
  } catch (error) {
    console.error("Login Error:", error.message); // Log any other error
    throw new Error(`Login failed: ${error.message}`);
  }
};
