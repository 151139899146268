<template>
  <div class="promo-container">
    <button class="back-button" @click="$router.push('/home')">Back</button>
    <div class="promo-card">
      <h2 class="title">Enter Promo Code</h2>

      <input
        v-model="promoCode"
        @keyup="fetchPromoDetails"
        class="promo-input"
        placeholder="Enter promo code"
      />

      <div v-if="promoDetails" class="promo-info">
        <p><strong>Author:</strong> {{ promoDetails.author }}</p>
        <p><strong>Amount:</strong> {{ promoDetails.amount }} Sense</p>
      </div>

      <!-- Show warning if the promo is already used -->
      <p v-if="promoUsed" class="promo-warning">Promo already used!</p>

      <button
        class="btn-apply"
        @click="applyPromoCode"
        :disabled="!promoDetails || promoUsed"
      >
        Use Code
      </button>

      <!-- Modal for showing result -->
      <div v-if="showResultModal" class="modal">
        <div class="modal-content">
          <p>{{ modalMessage }}</p>
          <button class="btn-close" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {doc, getDoc, getFirestore, updateDoc} from "firebase/firestore";

export default {
  data() {
    return {
      promoCode: '',
      promoDetails: null,
      promoUsed: false,
      showResultModal: false,
      modalMessage: '',
      usedPromos: [],
    };
  },
  computed: {
    userId() {
      return this.$store.getters['auth/user']?.uid;
    },
  },
  methods: {
    async fetchPromoDetails() {
      const promoCode = this.promoCode.trim().toUpperCase();
      if (!promoCode) return;

      try {
        const db = getFirestore();
        const promoRef = doc(db, 'promotionCodes', promoCode);
        const promoDoc = await getDoc(promoRef);

        if (promoDoc.exists()) {
          this.promoDetails = promoDoc.data();

          const userRef = doc(db, 'users', this.userId);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            this.usedPromos = userDoc.data().usedPromos || [];
            this.promoUsed = this.usedPromos.includes(promoCode);
          }
        } else {
          this.promoDetails = null;
        }
      } catch (error) {
        console.error('Error fetching promo code details:', error);
        this.promoDetails = null;
      }
    },
    async applyPromoCode() {
      if (!this.promoDetails || this.promoUsed) return;

      try {
        const db = getFirestore();
        const userRef = doc(db, 'users', this.userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const currentUsedPromos = userData.usedPromos || [];

          if (currentUsedPromos.includes(this.promoCode)) {
            this.modalMessage = 'You have already used this promo.';
          } else if (!this.promoDetails.active) {
            this.modalMessage = 'Promotion expired.';
          } else {
            await this.$store.dispatch('auth/updateSensePoints', this.promoDetails.amount);
            const updatedPromos = [...currentUsedPromos, this.promoCode];
            await updateDoc(userRef, { usedPromos: updatedPromos });

            this.modalMessage = `${this.promoDetails.amount} sense added!`;
          }

          this.showResultModal = true;
        } else {
          this.modalMessage = 'User document does not exist.';
          this.showResultModal = true;
        }
      } catch (error) {
        console.error('Error applying promo code:', error);
        this.modalMessage = 'An error occurred.';
        this.showResultModal = true;
      }
    },
    closeModal() {
      this.showResultModal = false;
      this.modalMessage = '';
    }
  }
};
</script>

<style scoped>
.promo-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  min-height: 100vh;
  padding: 1rem;
}

.promo-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #333;
}

.promo-input {
  padding: 0.5rem;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.promo-info {
  margin-bottom: 1rem;
}

.btn-apply {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-apply:disabled {
  background-color: #ccc;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
}

.btn-close {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .promo-card {
    padding: 1rem;
    max-width: 90%;
  }
}
</style>
