<template>
  <div class="disclaimer-container">
    <h2 class="title">Warning Statement (Disclaimer)</h2>
    <div class="disclaimer-content" @scroll="checkScroll">
      <p>
        All content presented here is intended to be used for entertainment purposes only. For legal reasons, we must say that all people and events contained herein are entirely fictional, even if real or resemble reality.
      </p>

      <p>
        The following content contains adult topics and controversial information. People under 18 years of age should play with a parent/guardian, or receive their consent. Our terms of use must be accepted by an adult (a person 18 years of age or older).
      </p>

      <p>
        We in no way claim to be an authoritative or all-inclusive source of facts or information and we strongly suggest that you do your own research and think for yourself. All borrowed content herein is used under fair use exceptions–unless it contains protected or copyrighted content, in which case it is cited to provide credit when credit is due. All information provided comes from accessible and verifiable sources and the links, articles, books, websites, or documents referenced within the game can be found in the ‘References’ section of our website.
      </p>

      <p class="game-warning">
        THIS IS A GAME: it is not intended to be offensive or used for any purpose other than entertainment and edification—we are all too aware that some of this information is upsetting or extremely disturbing. YOU PLAY THIS GAME AT YOUR OWN RISK. Please, always attend to your mental health and if you find something that disturbs you to the point you are uncomfortable or need to seek mental health services, exit the game immediately and do so. In fact, (shoutout to South Park) NO ONE SHOULD PLAY THIS GAME. The powers that be, along with any of the totally and entirely 100% fictional—even-if-real—people, places, groups, and characters presented in this game would NOT want you to play it.
      </p>

      <p>
        We would like to remind everyone that in no way is the content of this game a justification for harmful or criminal activity. SO… No funny business. But please, HAVE FUN. Be fun.
      </p>

      <p>
        And one last thing: Don’t take anything too seriously, we don’t. <span class="emoji">O:-D</span> Much love from us at CTG. Take care, Be well.
      </p>
      <p>Enjoy playing <strong>Conspiracy Trivia™</strong>!</p>
      <p class="footer-verse">Isaiah 32:(1-8)</p>
    </div>

    <button
      v-if="showButton"
      class="next-button"
      @click="goToNext"
    >
      Next
    </button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'DisclaimerView',
  data() {
    return {
      showButton: true
    };
  },
  methods: {
    // checkScroll(event) {
    //   const { scrollHeight, scrollTop, clientHeight } = event.target;
    //   this.showButton = scrollTop + clientHeight >= scrollHeight - 10;
    // },
    goToNext() {
      this.$router.push('/login'); // Proceed to the login page
    }
  }
};
</script>

<style scoped>
/* Ensure full viewport height and prevent overflow issues */
.disclaimer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  height: 100vh;
  box-sizing: border-box;
  background-color: black;
  color: white;
  overflow: hidden;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: #FFD700;
  text-align: center;
  margin-bottom: 1rem;
}

.disclaimer-content {
  flex-grow: 1;
  overflow-y: auto; /* Scrollable content */
  width: 100%;
  padding-right: 10px; /* Ensure no overlap with scrollbar */
  font-size: 1rem;
  line-height: 1.6;
}

.game-warning {
  font-weight: bold;
  color: #FF6347;
}

.footer-verse {
  font-style: italic;
  font-weight: bold;
  color: #999;
  text-align: right;
}

/* Next button fixed at the bottom, only appears when scrolled to bottom */
.next-button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.next-button:hover {
  background-color: #0056b3;
}
</style>
