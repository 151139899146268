<template>
  <div class="questions-container">
    <img src="@/assets/images/redacted.webp" class="redacted-image" alt="Redacted Background" />

    <div class="question-wrapper" v-if="!quizCompleted && questions.length > 0">
      <button class="back-button" @click="goBack">Back</button>

      <!-- Timer display -->
      <div class="timer" v-if="timerVisible && timer > 0">
        <p>Time Left: {{ timer }}s</p>
      </div>

      <div class="sense-paperclip-display">
        <i class="fas fa-paperclip paperclip-icon"> {{ paperclips }} </i>
      </div>

      <div class="question-content">
        <p class="question-number">{{ dossierId }}</p>
        <p class="question-number">Question {{ currentQuestionIndex + 1 }} of {{ questions.length }}</p>
        <span class="question-number">
          Sense: {{ totalSensePoints }}
          <span v-if="sensePointsChange !== 0">({{ sensePointsChange > 0 ? '+' : '' }}{{ sensePointsChange }})</span>
        </span>
        <p class="question">{{ currentQuestion.question }}</p>
      </div>

      <div class="answers">
        <button
          v-for="(answer, index) in processedAnswers"
          :key="index"
          :class="[
            'answer-button',
            feedbackState && String(answer).trim().toLowerCase() === String(currentQuestion.correctAnswer).trim().toLowerCase()
              ? 'correct'
              : feedbackState === 'incorrect' && String(selectedAnswer).trim() === String(answer).trim()
              ? 'incorrect'
              : ''
          ]"
          @click="checkAnswer(answer)"
          :disabled="feedbackState !== null"
        >
          {{ String(answer) }}
        </button>
      </div>
    </div>

    <!-- Sense animation when answering questions -->
    <div
      class="sense-animation"
      v-if="feedbackAnimation"
      :class="{ 'positive': feedbackState === 'correct', 'negative': feedbackState === 'incorrect' }"
    >
      <span>{{ feedbackState === 'correct' ? '+3' : '-2' }} Sense</span>
    </div>

    <!-- Quiz Results after completion -->
    <div class="results-wrapper" v-if="quizCompleted">
      <h1 class="results-title">Quiz Results</h1>
      <p class="results-summary">You got {{ correctAnswers }} out of {{ questions.length }} correct!</p>
      <div class="results-buttons">
        <button class="btn-outline disabled">Save Dossier (10 Cents)</button>
        <button class="btn-outline disabled">Next Dossier</button>
        <button class="btn-outline disabled">Categories</button>
        <button class="btn-outline" @click="goToLogin">Create Account to Play</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getDocs, collection } from "firebase/firestore"; // Import Firestore functions
import { getFirestore } from "firebase/firestore"; // Import Firestore

export default {
  name: "GuestView",
  data() {
    return {
      questions: [],
      currentQuestionIndex: 0,
      correctAnswers: 0,
      quizCompleted: false,
      feedbackState: null,
      totalSensePoints: 0,
      feedbackAnimation: false,
      sensePointsChange: 0,
      selectedAnswer: null,
      timer: 30,
      timerVisible: true,
      timerExpired: false,
      isLoading: true,
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex] || {};
    },
    processedAnswers() {
      const validAnswers = this.currentQuestion.answers
        ? this.currentQuestion.answers
          .filter(answer => answer !== 'N/A')
          .map(answer => String(answer).trim()) // Trim any extra whitespace
        : [];

      const specialAnswers = validAnswers.filter(answer => /all|none/i.test(answer));
      const regularAnswers = validAnswers.filter(answer => !/all|none/i.test(answer));

      // Shuffle the entire set of regular answers
      const randomAnswers = this.shuffleArray(regularAnswers);

      // Combine shuffled regular answers with special answers
      return [...randomAnswers, ...specialAnswers];
    }
  },
  methods: {
    goBack() {
      this.$router.push("/"); // Navigate back to homepage
    },
    checkAnswer(selectedAnswer) {
      clearInterval(this.timerInterval);
      this.timerVisible = false;
      this.selectedAnswer = String(selectedAnswer).trim();

      const correctAnswer = String(this.currentQuestion.correctAnswer).trim();

      if (this.selectedAnswer.toLowerCase() === correctAnswer.toLowerCase()) {
        this.correctAnswers++;
        this.feedbackState = "correct";
        this.sensePointsChange = 3;
        this.totalSensePoints += 3;
      } else {
        this.feedbackState = "incorrect";
        this.sensePointsChange = -2;
        this.totalSensePoints -= 2;
      }

      this.feedbackAnimation = true;

      setTimeout(() => {
        this.feedbackAnimation = false;
        this.feedbackState = null;
        this.selectedAnswer = null;
        this.moveToNextQuestion();
      }, 3000);
    },
    moveToNextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++;
        this.startTimer();
      } else {
        this.quizCompleted = true;
      }
    },
    async fetchQuestions() {
      const db = getFirestore();
      const questionsRef = collection(db, "questions", "INTRO", "First Dossier - Intro to Conspiracy Trivia");
      const questionsSnapshot = await getDocs(questionsRef);
      this.questions = questionsSnapshot.docs.map((doc) => doc.data());
      this.isLoading = false;
    },
    goToLogin() {
      this.$router.push("/login"); // Redirect to login
    },
    startTimer() {
      this.clearTimer();
      this.timer = 45;
      this.timerVisible = true;
      this.timerExpired = false;
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.timerExpired = true;
          clearInterval(this.timerInterval);
          this.moveToNextQuestion();
        }
      }, 1000);
    },
    clearTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
  },
  async mounted() {
    await this.fetchQuestions(); // Fetch questions for intro dossier
    this.startTimer();
  },
};
</script>

<style scoped>
/* Back button fixed at the top-left */
.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  font-size: 1rem;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.questions-container {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.redacted-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  animation: zoomIn 4s ease forwards;
  transform-origin: center;
}

/* Question wrapper during the quiz */
.question-wrapper {
  position: absolute;
  top: 10%;
  left: 5%;
  right: 5%;
  color: black;
  font-size: 1.5rem;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%; /* Set max-width to prevent overflow */
  width: 100%; /* Use the available width */
  margin: 0 auto;
  overflow-y: auto; /* Add scrollbar if question content overflows */
  max-height: 80vh; /* Limit the height to 60% of the viewport height */
  box-sizing: border-box; /* Include padding and border in width/height */
}


.answers {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.answer-button {
  background-color: white;
  border: 2px solid black;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.answer-button.correct {
  background-color: green;
  color: white;
}

.answer-button.incorrect {
  background-color: red;
  color: white;
}

.timer {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center; /* Center align the timer text */
}

.sense-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #ff1177, 0 0 25px #ff1177, 0 0 30px #ff1177, 0 0 35px #ff1177;
  /* Default glow effect */
}

.sense-animation.positive {
  color: green;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000, 0 0 25px #1177ff, 0 0 30px #1177ff, 0 0 35px #1177ff;
  /* Blue glow effect */
}

.sense-animation.negative {
  color: red;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000, 0 0 25px #ff1177, 0 0 30px #ff1177, 0 0 35px #ff1177;
  /* Red glow effect */
}

/* Timer Expired Animation */
.timer-expired-animation {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: red;
  font-weight: bold;
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;
  animation: zoomInEffect 1s ease-out forwards; /* Apply zoom-in animation */
  text-shadow: 0 0 5px #000000, 0 0 10px #000000, 0 0 15px #000000, 0 0 20px #000000, 0 0 25px #ff1177, 0 0 30px #ff1177, 0 0 35px #ff1177;
}

/* Modal for saving options */
.save-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.save-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-save {
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-save:hover {
  background-color: black;
  color: white;
}

.btn-cancel {
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid red;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-cancel:hover {
  background-color: red;
  color: white;
}

.paperclip-icon {
  position: absolute; /* Position relative to the question-wrapper */
  top: 0.5rem; /* Position inside the white question container */
  right: 0.5rem;
  font-size: 2rem; /* Smaller size for the icon */
  color: #333;
  cursor: pointer;
  z-index: 10; /* Ensure it's above other content in the question-wrapper */
}

.question-number {
  font-size: .8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.results-wrapper {
  position: absolute;
  top: 25%;
  left: 5%;
  right: 5%;
  text-align: center;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
}

.results-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.results-summary {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.results-buttons {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.btn-outline {
  padding: 0.75rem 1.5rem;
  border: 2px solid black;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-outline:hover {
  background-color: black;
  color: white;
}

/* Completed Message Modal */
.completed-message-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000; /* Ensure it’s above other elements */
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 80vw; /* Adjust as needed */
  max-height: 80vh;
  overflow-y: auto; /* Add scroll for overflow */
}

/* Completed Message Modal Content */
.completed-message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
}

.btn-continue {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid black;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-continue:hover {
  background-color: black;
  color: white;
}

@media (max-width: 800px) {
  .question-wrapper {
    top: 5%;
    left: 2%;
    right: 2%;
    padding: 0.5rem;
    max-height: 85vh; /* Increase max height to fit more content */
    font-size: 1rem;
  }

  .back-button {
    padding: 0.3rem 0.6rem;
    font-size: 0.9rem;
    top: 0.5rem;
    left: 0.5rem;
  }

  .timer {
    font-size: 1rem;
  }

  .question-number{
    font-size: .6rem;
  }
  .question {
    font-size: 1rem; /* Reduce font size */
  }

  .answers {
    margin-top: 0.5rem;
  }

  .answer-button {
    padding: 0.5rem;
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }

  .sense-animation {
    font-size: 1.5rem;
  }

  .timer-expired-animation {
    font-size: 2rem;
  }

  .save-modal,
  .completed-message-modal,
  .results-wrapper {
    padding: 1rem;
    max-width: 90vw;
    max-height: 90vh;
  }

  .results-title,
  .results-summary {
    font-size: 1.3rem;
  }

  .btn-outline,
  .btn-save,
  .btn-cancel,
  .btn-continue {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .paperclip-icon {
    font-size: 1.2rem;
    top: 0.3rem;
    right: 0.3rem;
  }
}

/* Modal for saving options */
.save-modal,
.modal.show,
.completed-message-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 9999; /* Ensure it's above all other elements */
}
.modal {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

/* Modal Dialog Fixes */
.modal-dialog {
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
  margin: 0;
  padding: 0;
}

/* Modal Content Specific Styles */
.modal-content {
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border: none;
  background-color: white !important;
  max-height: 95vh; /* Limit height to 85% of viewport */
  overflow-y: auto; /* Add scroll if content overflows */
}

/* Modal Header */
.modal-header {
  background-color: #1f1f1f;
  color: white;
  padding: 1.2rem;
  border-bottom: none;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-title {
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Modal Body */
.modal-body {
  padding: 1.5rem;
  font-size: 1.2rem;
  color: #333;
  text-align: center;
}

/* Modal Footer */
.modal-footer {
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-top: none;
}

/* Buttons inside the modal footer */
.modal-footer .btn {
  padding: 0.8rem 2rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
}
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

@keyframes zoomInEffect {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5); /* Start smaller and transparent */
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.5); /* Increase size and make visible */
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2); /* Zoom out and fade away */
  }
}
</style>
