<template>

  <div v-if="showModal" class="modal-overlay">
    <div class="modal-content">
      <p>Phone Users, Turn your Phone Sideways to View the Game for Correct Visuals, except during the Questions.</p>
      <button class="modal-button" @click="closeModal">Okay</button>
    </div>
  </div>
  <div class="opening-container" @click="openGame">
    <!-- Modal overlay -->
    <div class="marquee" >
      <!-- Add any text or logo inside the marquee if needed -->
    </div>
    <div class="door-button" @click.stop="openGame">
      <img src="https://attic.sh/5qibwjmbhjbyo2ejauq6s17vqwv0" alt="Door">
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'OpeningView',
  data() {
    return {
      showModal: true, // Show modal on load
    };
  },
  methods: {
    handleClick (event) {
      if (!this.$el.querySelector('.door-button').contains(event.target)) {
        this.flashDoor();
      }
    },
    flashDoor () {
      const door = this.$el.querySelector('.door-button');
      door.classList.add('flash');
      setTimeout(() => {
        door.classList.remove('flash');
      }, 1000);
    },
    openGame () {
      this.$router.push('/disclaimer');
    },
    closeModal() {
      this.showModal = false; // Close the modal
    },
  },
};
</script>

<style scoped>
/* Opening container and pulse effect */
.opening-container {
  background: url('@/assets/images/opening.png') no-repeat center center;
  background-size: 100% 100%;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  animation: pulse 10s infinite ease-in-out;
}

/* Modal overlay styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.modal-content p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;
}

.modal-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-button:hover {
  background-color: #0056b3;
}

/* Door button styling */
.door-button {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
}

.door-button img {
  width: 10vw;
  max-width: 150px;
  height: auto;
  filter: drop-shadow(0 0 10px yellow);
  transition: filter 0.3s ease;
}

.door-button:hover img {
  filter: drop-shadow(0 0 20px yellow);
}

.flash img {
  filter: drop-shadow(0 0 20px red);
}

/* Keyframes for the pulse effect */
@keyframes pulse {
  0%, 100% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 105% 105%;
  }
}

/* Adjust door-button size for smaller screens */
@media (max-width: 600px) {
  .door-button img {
    width: 15vw;
    max-width: 100px;
  }
}
</style>
