<template>
  <div class="desktop-container">
    <!-- Desktop background -->
    <img src="@/assets/images/desktop.png" class="desktop-image" alt="Desktop Background" />

    <!-- Folder icons container -->
    <div class="folder-icons-wrapper">
      <div class="folder-icon" v-for="category in categories" :key="category.id" @click="openExplorer(category)">
        <i class="fa fa-folder"></i>
        <p>{{ category.name }}</p>
      </div>
    </div>

    <!-- File Explorer Pop-up (shown when a folder is clicked) -->
    <div v-if="isExplorerOpen" class="file-explorer">
      <img src="@/assets/images/fileExplorer.png" class="explorer-image" alt="File Explorer" />
      <button class="btn-close close-button" @click="closeExplorer">X</button>

      <ul class="dossier-list">
        <li v-for="(dossier, index) in selectedCategory.dossiers"
            :key="index"
            class="dossier-item"
            @click="checkDossierWarning(selectedCategory.id, dossier)">
          <i :class="getDossierIconClass(selectedCategory.id, dossier)" class="dossier-icon"></i>
          <span class="dossier-text">{{ dossier }}</span>
        </li>
      </ul>
    </div>

    <!-- Conditional button based on whether the explorer is open or not -->
    <button class="btn btn-secondary home-button" @click="isExplorerOpen ? closeExplorer() : goToHomePage()">
      {{ isExplorerOpen ? 'Categories' : 'Back to Office' }}
    </button>

    <!-- Purchase Modal for Locked Dossier -->
    <div v-if="showPurchaseModal" class="modal show d-block" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Purchase Dossier</h5>
            <button type="button" class="btn-close" @click="cancelPurchase"></button>
          </div>
          <div class="modal-body">
            <p>{{ purchaseMessage }}</p>
          </div>
          <div v-if="!purchaseConfirmed" class="modal-footer">
            <button v-if="promptBuyMore" @click="redirectToPayPal" class="btn btn-primary">Yes, Buy More Sense</button>
            <button v-else @click="confirmPurchase" class="btn btn-primary">Yes</button>
            <button @click="cancelPurchase" class="btn btn-secondary">No</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Warning Modal for Dossier with disturbing content -->
    <div v-if="showWarningModal" class="warning-modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Content Warning</h5>
            <button type="button" class="btn-close" @click="closeWarningModal">X</button>
          </div>
          <div class="modal-body">
            <p>This dossier contains disturbing content:</p>
          </div>
          <div class="modal-footer">
            <button @click="proceedToDossier" class="btn-primary">Proceed</button>
            <button @click="closeWarningModal" class="btn-secondary">Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { getFirestore, collection, getDocs, doc, getDoc, updateDoc } from "firebase/firestore";
import { mapGetters } from "vuex";

export default {
  name: 'DesktopView',
  data() {
    return {
      isExplorerOpen: false,
      categories: [],
      dossiers: [],
      selectedCategory: null,
      showPurchaseModal: false,
      showWarningModal: false, // For warning modal
      dossierToPurchase: null,
      dossierToProceed: null, // To keep track of the selected dossier if proceeding
      purchaseMessage: 'This Dossier has not yet been unlocked. Would you like to purchase it now for 100 sense?',
      purchaseConfirmed: false, // Track if purchase has been confirmed
    };
  },
  computed: {
    ...mapGetters('auth', ['purchasedDossiers', 'completedDossiers', 'sense']),
  },
  methods: {
    getDossierIconClass(categoryId, dossier) {
      const purchased = this.purchasedDossiers.some(
        item => item.category === categoryId && item.dossier === dossier
      );
      const completedDossier = this.completedDossiers.find(
        item => item.category === categoryId && item.dossier === dossier
      );

      if (completedDossier) {
        const [score, total] = completedDossier.finalScore.split('/').map(Number);
        if (score === total) return 'fa fa-star status-perfect'; // Perfect score (show star)
        return 'fa fa-circle status-complete'; // Completed but not perfect (show green dot)
      }

      return purchased ? 'fa fa-circle status-purchased' : 'custom-key-icon'; // Yellow dot if purchased, custom key icon if not
    },

    async fetchCategories() {
      const db = getFirestore();
      const categoriesCollection = collection(db, 'questions');

      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = [];

      for (const categoryDoc of categoriesSnapshot.docs) {
        const categoryData = categoryDoc.data();
        const dossiers = categoryData.dossiers || [];

        categoriesData.push({
          id: categoryDoc.id,
          name: categoryDoc.id,
          dossiers: dossiers
        });
      }

      this.categories = categoriesData;
    },

    async openExplorer(category) {
      try {
        const db = getFirestore();
        const categoryRef = doc(db, 'questions', category.id);
        const categoryDoc = await getDoc(categoryRef);

        if (categoryDoc.exists()) {
          const categoryData = categoryDoc.data();
          let dossiersData = categoryData.dossiers || [];

          // Sort dossiers alphabetically
          dossiersData.sort();

          // Reorder dossiers to appear in two columns by alternating
          const reorderedDossiers = [];
          const middleIndex = Math.ceil(dossiersData.length / 2);

          for (let i = 0; i < middleIndex; i++) {
            reorderedDossiers.push(dossiersData[i]); // Left column item
            if (dossiersData[i + middleIndex]) {
              reorderedDossiers.push(dossiersData[i + middleIndex]); // Right column item
            }
          }

          this.selectedCategory = {
            id: category.id,
            name: category.name,
            dossiers: reorderedDossiers,
          };
          this.isExplorerOpen = true;
        } else {
          console.error("No category document found:", category.id);
        }
      } catch (error) {
        console.error("Error opening explorer:", error);
      }
    },

    closeExplorer() {
      this.isExplorerOpen = false;
    },

    async checkDossierWarning(categoryId, dossier) {
      try {
        const db = getFirestore();
        const categoryRef = doc(db, 'questions', categoryId);
        const categoryDoc = await getDoc(categoryRef);

        if (categoryDoc.exists()) {
          const dossierInfo = categoryDoc.data().dossiersInfo || {};
          const dossierData = dossierInfo[dossier] || {};

          if (dossierData.warning === 'YES') {
            // Show warning modal if dossier contains disturbing content
            this.showWarningModal = true;
            this.dossierToProceed = { categoryId, dossier };
          } else {
            // No warning, directly check if dossier is purchased
            await this.goToQuestions(categoryId, dossier);
          }
        } else {
          console.error('No dossier info found.');
        }
      } catch (error) {
        console.error("Error checking dossier warning:", error);
      }
    },

    proceedToDossier() {
      this.showWarningModal = false;
      if (this.dossierToProceed) {
        const { categoryId, dossier } = this.dossierToProceed;

        // Check if the dossier is purchased after closing the warning modal
        const isPurchased = this.purchasedDossiers.some(
          item => item.category === categoryId && item.dossier === dossier
        );

        if (!isPurchased) {
          // If not purchased, show the purchase prompt
          this.promptPurchase(categoryId, dossier);
        } else {
          // If purchased, navigate to the questions view
          this.$router.push({ name: 'QuestionsView', params: { categoryId, dossierId: dossier } });
        }
      }
    },

    closeWarningModal() {
      this.showWarningModal = false;
    },

    async goToQuestions(categoryId, dossier) {
      // Ensure no modal overlaps: don't proceed if warning modal is still open
      if (this.showWarningModal) return;

      const isPurchased = this.purchasedDossiers.some(
        item => item.category === categoryId && item.dossier === dossier
      );

      if (!isPurchased) {
        // If not purchased, prompt for purchase
        this.promptPurchase(categoryId, dossier);
      } else {
        // If purchased, navigate to the questions view
        this.$router.push({ name: 'QuestionsView', params: { categoryId, dossierId: dossier } });
      }
    },

    promptPurchase(categoryId, dossier) {
      this.dossierToPurchase = { category: categoryId, dossier };
      this.purchaseMessage = 'This Dossier has not yet been unlocked. Would you like to purchase it now for 100 sense?';
      this.purchaseConfirmed = false;
      this.showPurchaseModal = true;
    },

    async confirmPurchase() {
      const { category, dossier } = this.dossierToPurchase;

      if (this.sense >= 100) {
        await this.$store.dispatch('auth/updateSensePoints', -100);

        const updatedPurchasedDossiers = [...this.purchasedDossiers, { category, dossier }];
        await updateDoc(doc(getFirestore(), 'users', this.$store.getters['auth/user'].uid), {
          purchasedDossiers: updatedPurchasedDossiers,
        });

        this.$store.commit('auth/SET_PURCHASED_DOSSIERS', updatedPurchasedDossiers);
        this.purchaseMessage = 'Purchase successful!';
        this.purchaseConfirmed = true;

        // Automatically navigate to QuestionsView after purchase
        this.$router.push({ name: 'QuestionsView', params: { categoryId: category, dossierId: dossier } });

      } else {
        // Show message and prompt to buy more points
        this.purchaseMessage = 'Not enough sense points. Would you like to purchase more?';
        this.purchaseConfirmed = false;
        this.promptBuyMore = true;
      }

      setTimeout(() => {
        if (!this.promptBuyMore) { // Only close if no buy-more prompt is open
          this.showPurchaseModal = false;
          this.dossierToPurchase = null;
          this.purchaseMessage = '';
        }
      }, 15000);
    },
    redirectToPayPal() {
      this.showPurchaseModal = false;
      this.$router.push('/paypal'); // Redirects to the PayPal view
    },
    cancelPurchase() {
      this.showPurchaseModal = false;
      this.purchaseMessage = '';
    },

    goToHomePage() {
      this.$router.push('/home');
    }
  },
  mounted() {
    this.fetchCategories();
  }
};
</script>

<style scoped>
/* General reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Desktop Container */
.desktop-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

/* Desktop background image */
.desktop-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  z-index: 0;
}

/* Folder icons wrapper */
.folder-icons-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
  gap: 2rem;
  align-items: flex-start;
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
}

/* Folder icons for categories */
.folder-icon {
  width: 10vw;
  text-align: center;
  cursor: pointer;
  color: #fff;
}

.folder-icon p {
  margin-top: 5px;
  font-size: 1.5vw;
  color: #fff;
}

.folder-icon i {
  font-size: 4vw;
  color: yellow;
}

/* Full-Screen File Explorer pop-up */
.file-explorer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.explorer-image {
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  background-color: white;
}

/* Close button */
.close-button {
  position: fixed;
  top: 0;
  right: 0;
  background-color: transparent;
  border: transparent;
  color: transparent;
  width: 7rem;
  height: 7rem;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 3;
}

.close-button:hover {
  color: red;
}

/* Home button */
.home-button {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: red;
  border: none;
  color: white;
  padding: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 3;
}


.home-button:hover {
  background-color: #0056b3;
}

/* Dossier list inside the file explorer */
.dossier-list {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 80%;
  list-style-type: none;
  font-size: 2vw;
  z-index: 4;
  display: flex;
  flex-wrap: wrap;
  max-height: 70vh; /* Set a max-height for the container */
  overflow-y: auto;
}

/* Dossier folder and text styling */
.dossier-item {
  z-index: 5;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 1.5vw;
  width: calc(50% - 1rem);
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.dossier-item:hover {
  background-color: #e2e6ea;
}

.dossier-icon {
  margin-right: 10px;
  font-size: 2vw;
}

.dossier-text {
  font-size: 1.8vw;
}

.status-perfect {
  color: gold; /* Star color for perfect score */
}

.status-complete {
  color: green; /* Green dot for completed */
}

.status-purchased {
  color: yellow; /* Yellow dot for purchased */
}

/* Custom key icon for not purchased */
.custom-key-icon {
  background-image: url('@/assets/images/logoKey.png');
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Modal Common */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  z-index: 1000;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
}

.modal-title {
  font-size: 1.25rem;
}

.modal-body {
  margin-top: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.modal-footer button {
  width: 48%;
}

/* Buttons */
.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

/* Purchase Modal */
.purchase-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  z-index: 1000;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.purchase-modal-content {
  font-size: 1.2rem;
}

.purchase-message {
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}

/* Warning Modal */
.warning-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  z-index: 1050;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.warning-modal-content {
  font-size: 1.2rem;
}


</style>
