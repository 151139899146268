import { initializeApp } from 'firebase/app'

const prodConfig = {
  apiKey: 'AIzaSyAW6HGw-3d6iCt-V8gikd7BvBo1kKLQv64',
  authDomain: 'conspiracytrivia-c5a1f.firebaseapp.com',
  databaseURL: 'https://conspiracytrivia-c5a1f-default-rtdb.firebaseio.com',
  projectId: 'conspiracytrivia-c5a1f',
  storageBucket: 'conspiracytrivia-c5a1f.appspot.com',
  messagingSenderId: '915684042204',
  appId: '1:915684042204:web:dd7bd4c9b862ed827e5b19',
  measurementId: 'G-QZSYCD8LP6'
}

const testConfig = {
  apiKey: 'AIzaSyAb1YPROaRz4azZRyYcUbQzGBroXYADei4',
  authDomain: 'conspiracytriviatest.firebaseapp.com',
  projectId: 'conspiracytriviatest',
  storageBucket: 'conspiracytriviatest.appspot.com',
  messagingSenderId: '271518759431',
  appId: '1:271518759431:web:e1b049c83e9b9fb34bc8a2'
}

// Set a manual flag to determine which config to use
const useProd = true

// Choose the appropriate config based on the flag
const firebaseConfig = useProd ? prodConfig : testConfig

// Initialize Firebase with the selected configuration
const firebaseApp = initializeApp(firebaseConfig)

export default firebaseApp
