<template>
  <div class="folder-container">
    <!-- Back Button -->
    <button class="back-button" @click="goBack">
      <i class="fa fa-arrow-left"></i> Back
    </button>

    <img src="@/assets/images/folder.png" class="folder-image" alt="Folder Image" />

    <!-- Tabs for switching between Dossiers and Questions -->
    <div class="tabs">
      <button
        class="tab"
        :class="{ active: activeTab === 'dossiers' }"
        @click="activeTab = 'dossiers'">Dossiers</button>
      <button
        class="tab"
        :class="{ active: activeTab === 'questions' }"
        @click="activeTab = 'questions'">Questions</button>
    </div>

    <!-- Scrollable container for saved results or saved questions -->
    <div class="results-container">
      <!-- Dossiers tab content -->
      <div v-if="activeTab === 'dossiers'">
        <ul class="result-list" v-if="savedResults.length > 0">
          <li v-for="(result, index) in savedResults" :key="index" class="result-item">
            <span class="result-title">Dossier: {{ result.dossierId }} - {{ result.correctAnswers }} / {{ result.totalQuestions }} Correct</span>
          </li>
        </ul>
        <div v-if="savedResults.length === 0" class="no-results">
          <p>No saved dossiers found.</p>
        </div>
      </div>

      <!-- Questions tab content -->
      <div v-if="activeTab === 'questions'">
        <ul class="result-list" v-if="savedQuestions.length > 0">
          <li v-for="(question, index) in savedQuestions" :key="index" class="result-item">
            <span class="result-title">Question: {{ question.question }}</span>
          </li>
        </ul>
        <div v-if="savedQuestions.length === 0" class="no-results">
          <p>No saved questions found.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default {
  name: 'FolderView',
  data() {
    return {
      savedResults: [], // Store the user's saved quiz results (dossiers)
      savedQuestions: [], // Store the user's saved questions
      activeTab: 'dossiers' // Track the active tab
    };
  },
  methods: {
    async fetchSavedResults() {
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        try {
          // Fetch saved dossiers
          console.log("Fetching saved dossiers for user:", user.uid);
          const resultsQuery = query(
            collection(db, 'savedDossiers'),
            where('userId', '==', user.uid)
          );
          const resultsSnapshot = await getDocs(resultsQuery);
          const resultsData = [];
          resultsSnapshot.forEach(doc => {
            resultsData.push(doc.data());
          });
          console.log("Fetched dossiers:", resultsData);
          this.savedResults = resultsData;

          // Fetch saved questions
          console.log("Fetching saved questions for user:", user.uid);
          const questionsQuery = query(
            collection(db, 'savedQuestions'),
            where('userId', '==', user.uid)
          );
          const questionsSnapshot = await getDocs(questionsQuery);
          const questionsData = [];
          questionsSnapshot.forEach(doc => {
            questionsData.push(doc.data());
          });
          console.log("Fetched questions:", questionsData);
          this.savedQuestions = questionsData;
        } catch (error) {
          console.error("Error fetching saved results or questions:", error);
        }
      } else {
        console.error("User is not authenticated.");
      }
    },
    goBack() {
      this.$router.go(-1); // Go back to the previous route
    }
  },
  mounted() {
    // Lock viewport by preventing scrolling
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100vw';
    document.body.style.height = '100vh';

    setTimeout(() => {
      document.querySelector('.folder-image').classList.add('zoom-in');
    }, 50); // Delay ensures image is rendered before applying zoom effect

    // Fetch saved quiz results and saved questions
    this.fetchSavedResults();
  },
  beforeDestroy() {
    // Restore scroll behavior when leaving the view
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.height = '';
  }
};
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevents scrolling */
}

body {
  background-color: #000; /* Set background color to black in case image doesn't fully cover */
}

.folder-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Ensure no scrollbars appear */
}

.folder-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transform: scale(1);
  transition: transform 2s ease;
}

.folder-image.zoom-in {
  transform: scale(2) translateY(-15%);
}

/* Tabs for Dossiers and Questions */
.tabs {
  position: absolute;
  top: 2%;
  right: 15%;
  display: flex;
  justify-content: center;
}

.tab {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: 1px solid white;
  background-color: black;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.tab.active {
  background-color: white;
  color: black;
}

/* Back button in the top-left corner */
.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  color: white;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  font-size: 1rem;
}

.back-button i {
  margin-right: 0.5rem;
}

/* Scrollable container for the result list */
.results-container {
  position: absolute;
  top: calc(10%); /* Adjust to move it into the folder area */
  right: calc(13%);
  width: calc(30% + 9vw); /* Similar dynamic width to the example */
  height: calc(80% + 2vh); /* Similar dynamic height to the example */
  overflow-y: auto; /* Scrollable content */
  background: rgba(255, 255, 255, 0); /* Transparent background */
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for effect */
}

/* Adjust the result list to fit inside the scrollable container */
.result-list {
  color: #444; /* Dark text to mimic writing */
  font-family: 'Courier New', Courier, monospace; /* Typewriter font style */
  font-size: 1.2rem;
  background: none; /* Remove background */
  padding: 0; /* Remove padding */
}

.result-item {
  margin-bottom: 1.5rem;
}

.result-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.no-results {
  position: relative;
  z-index: 1;
  margin-top: 30vh;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.no-results p {
  font-size: 1.5rem;
  color: #333;
}
</style>
