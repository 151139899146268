<template>
  <div class="login-container">
    <div class="notebook-overlay" v-if="animationFinished">
      <div class="form-section">
        <form @submit.prevent="handleLogin" class="form-box">
          <h2 class="title">Secret Agent Login</h2>
          <div class="form-group">
            <input v-model="email" id="email" type="email" class="form-control" placeholder="Enter your email" required />
          </div>
          <div class="form-group">
            <input v-model="password" id="password" type="password" class="form-control" placeholder="Enter your password" required />
          </div>
          <div v-if="truncatedErrorMessage" class="form-group">
            <div class="alert alert-danger" role="alert" @click="clearErrorMessage">
              {{ truncatedErrorMessage }}
            </div>
          </div>
          <div class="button-group">
            <button type="submit" class="btn-outline btn-size">Enter the Office</button><br>
            <button @click="toggleCreateAccount" class="btn-outline btn-size">Create Account</button>
            <!-- Add Terms of Use button here -->
          </div>
        </form>
      </div>
      <div class="form-section right-page">
        <h2 class="subtitle">Guest Login</h2>
        <button @click="proceedAsGuest" class="btn-outline btn-size">Proceed as Guest</button>
        <button @click="goToTermsOfUse" class="btn-outline btn-size">Terms of Use</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      animationFinished: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['error', 'firstLogin']),
    truncatedErrorMessage() {
      return this.errorMessage.length > 50 ? this.errorMessage.substring(0, 50) + '...' : this.errorMessage;
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'register']),

    async handleLogin() {
      const loginResponse = await this.login({
        email: this.email,
        password: this.password,
      });

      if (loginResponse && loginResponse.error) {
        this.errorMessage = loginResponse.error;
        console.error("Login Error:", loginResponse.error); // Log error message
        this.autoClearErrorMessage();
      } else {
        if (!this.error) {
          this.errorMessage = '';
          if (this.firstLogin) {
            this.$router.push('/termsofuse');
          } else {
            this.$router.push('/home');
          }
        }
      }
    },

    async toggleCreateAccount() {
      try {
        const registerResponse = await this.register({
          email: this.email,
          password: this.password,
        });

        if (registerResponse && registerResponse.error) {
          this.errorMessage = registerResponse.error;
          console.error("Registration Error:", registerResponse.error); // Log error message
          this.autoClearErrorMessage();
        } else {
          // Add a short delay before logging in
          await new Promise(resolve => setTimeout(resolve, 2000));
          await this.handleLogin(); // Attempt to log in immediately after account creation
          this.errorMessage = '';
        }
      } catch (error) {
        console.error("Error during account creation:", error.message);
        this.errorMessage = error.message;
        this.autoClearErrorMessage();
      }
    },

    proceedAsGuest() {
      this.$store.dispatch('auth/setGuest');
      this.$router.push('/termsofuse');
    },

    clearErrorMessage() {
      this.errorMessage = '';
    },

    autoClearErrorMessage() {
      setTimeout(() => {
        this.errorMessage = '';
      }, 5000);
    },

    // Method to navigate to the Terms of Use view
    goToTermsOfUse() {
      this.$router.push('/termsofuse');
    }
  },

  mounted() {
    setTimeout(() => {
      this.animationFinished = true;
    }, 4000);
  }
};
</script>

<style scoped>
/* General reset for all elements to prevent override issues */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 230px; /* Match the width of the buttons */
  position: relative;
}

/* Lock container and background to screen size, starting with the zoomed-out position */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: url('/src/assets/images/login.webp') no-repeat center center;
  background-size: 150%; /* Start with a zoomed-out background */
  overflow: hidden;
  position: relative;
  animation: zoomIn 4s ease forwards; /* Apply zoom-in animation */
}

/* Flexbox overlay for content alignment */
.notebook-overlay {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  height: 80vh;
  gap: 7rem;
}

/* Align form sections properly */
.form-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 45%;
}

/* Set exact width and height for both textboxes and buttons */
.form-control, .btn-outline {
  width: 230px;
  height: 40px;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 2px solid black;
  border-radius: 4px;
  background-color: transparent;
  text-align: left;
  box-sizing: border-box;
}

.btn-outline {
  width: 230px;
  height: 40px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
}

.btn-outline:hover {
  background-color: black;
  color: white;
}

/* Title alignment and size adjustments */
.title, .subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: black;
  text-align: left;
  margin-bottom: 0.5rem;
}

/* Shrink titles for smaller screens to keep them on one line */
@media (max-width: 600px) {
  .title, .subtitle {
    font-size: 1.2rem;
  }

  .form-control, .btn-outline {
    width: 200px;
    height: 35px;
    font-size: 0.85rem;
  }
}

@media (max-width: 400px) {
  .notebook-overlay {
    flex-direction: column;
    align-items: center;
  }

  .form-section {
    width: 90%;
  }

  .form-control, .btn-outline {
    width: 180px;
    height: 30px;
    font-size: 0.8rem;
  }

  .title, .subtitle {
    font-size: 1rem;
  }
}

/* Zoom animation keyframes for the background */
@keyframes zoomIn {
  0% {
    background-size: 150%; /* Start zoomed out */
  }
  100% {
    background-size: 225%; /* Zoom into the final size */
  }
}
</style>
