<template>
  <div class="auth-container">
    <h2>Create Account</h2>
    <form @submit.prevent="handleRegister">
      <input v-model="email" type="email" placeholder="Email" required />
      <input v-model="password" type="password" placeholder="Password" required />
      <button type="submit">Create Account</button>
    </form>
    <p>{{ error }}</p>
    <router-link to="/login">Already have an account? Login</router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CreateAccount',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['error'])
  },
  methods: {
    ...mapActions('auth', ['register']),
    async handleRegister () {
      await this.register({ email: this.email, password: this.password })
      if (!this.error) {
        this.$router.push('/home')
      }
    }
  }
}
</script>

<style scoped>
.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

button {
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

p {
  color: red;
}

@media (max-width: 600px) {
  .auth-container {
    padding: 0.5rem;
  }

  input, button {
    font-size: 0.9rem;
  }
}
</style>
