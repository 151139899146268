<template>
  <div class="welcome-container">
    <div class="typewriter-wrapper">
      <div class="typewriter" ref="typewriter">
        <p v-for="line in displayedLines" :key="line">{{ line }}</p>
      </div>
    </div>

    <div v-if="finishedTyping" class="action-buttons">
      <button @click="agree" class="action-button agree-button">I Agree</button>
      <button @click="disagree" class="action-button disagree-button">I Disagree</button>
    </div>

    <button class="skip-button" @click="skipTyping">Skip</button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "WelcomeView",
  data() {
    return {
      lines: [
        "The future is in your hands, Agent.",
        "Remember: you enter reality at your own risk.",
        "Again, welcome to the mission,",
        "And welcome to the rabbit hole",
      ],
      displayedLines: [],
      finishedTyping: false,
      typingInterval: null,
    };
  },
  methods: {
    typeLine(line) {
      return new Promise((resolve) => {
        let currentText = "";
        const chars = line.split("");
        this.typingInterval = setInterval(() => {
          if (chars.length > 0) {
            currentText += chars.shift();
            this.displayedLines[this.displayedLines.length - 1] = currentText;
          } else {
            clearInterval(this.typingInterval);
            resolve();
          }
        }, 50);
      });
    },
    async typewriterEffect() {
      for (const line of this.lines) {
        this.displayedLines.push("");
        await this.typeLine(line);
      }
      this.finishedTyping = true;
    },
    skipTyping() {
      clearInterval(this.typingInterval);
      this.displayedLines = [...this.lines];
      this.finishedTyping = true;
    },
    async agree() {
      await this.$store.dispatch("auth/setFirstLogin", false);
      this.$router.push({
        name: 'QuestionsView',
        params: { categoryId: 'INTRO', dossierId: 'First Dossier - Intro to Conspiracy Trivia' }
      });
    },
    disagree() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.typewriterEffect();
  },
};
</script>

<style scoped>
.welcome-container {
  background: black url('@/assets/images/disclaimer.webp') no-repeat center center;
  background-size: 160%;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: green;
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  animation: zoomIn 7s ease forwards;
}

.typewriter-wrapper {
  width: 50%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.typewriter {
  font-size: 2vw;
  white-space: pre-wrap;
  text-align: justify;
  padding: 1rem;
  overflow-y: auto;
}

.skip-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  font-size: 1rem;
}

.action-buttons {
  position: fixed;
  bottom: 1rem;
  display: flex;
  gap: 1rem;
}

.action-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.agree-button {
  border-color: green;
  color: green;
  background-color: black;
}

.disagree-button {
  border-color: red;
  color: red;
  background-color: black;
}
</style>
