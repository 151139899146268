/* eslint-disable */
import { login, register } from '@/services/authService';
import {getFirestore, collection, addDoc, doc, updateDoc, getDoc, setDoc} from "firebase/firestore";

// Utility functions for saving and loading state to/from localStorage
function saveStateToLocalStorage(state) {
  const persistedState = {
    user: state.user,
    agentName: state.agentName,
    firstLogin: state.firstLogin,
    sense: state.sense,
    purchasedDossiers: state.purchasedDossiers,
    inProgressDossiers: state.inProgressDossiers,
    completedDossiers: state.completedDossiers,
    guest: state.guest,
    paperclips: initialState.paperclips || 0,
  };
  localStorage.setItem('vuex', JSON.stringify(persistedState));
}

function loadStateFromLocalStorage() {
  const persistedState = localStorage.getItem('vuex');
  return persistedState ? JSON.parse(persistedState) : {};
}

// Initialize state with data from localStorage if available
const initialState = loadStateFromLocalStorage();

const state = {
  user: initialState.user || null,
  guest: initialState.guest || false,
  agentName: initialState.agentName || '',
  firstLogin: initialState.firstLogin || false,
  sense: initialState.sense || 0,
  error: null,
  purchasedDossiers: initialState.purchasedDossiers || [],
  inProgressDossiers: initialState.inProgressDossiers || [],
  completedDossiers: initialState.completedDossiers || [],
  paperclips: initialState.paperclips || 0, // Track paperclips
};

const mutations = {
  USE_PAPERCLIP(state, newPaperclipCount) {
    state.paperclips = newPaperclipCount;
    saveStateToLocalStorage(state); // Keep state synced with localStorage
  },
  SET_PAPERCLIPS(state, paperclips) {
    console.log("Setting paperclips in mutation: ", paperclips);
    state.paperclips = paperclips; // Update the state with the new paperclip count
    saveStateToLocalStorage(state); // Save to localStorage
  },
  SET_USER(state, user) {
    state.user = user;
    saveStateToLocalStorage(state);
  },
  SET_FIRST_LOGIN(state, firstLogin) {
    state.firstLogin = firstLogin;
    saveStateToLocalStorage(state);
  },
  SET_AGENT_NAME(state, agentName) {
    state.agentName = agentName;
    saveStateToLocalStorage(state);

    if (state.user && state.user.uid) {
      updateDoc(doc(getFirestore(), 'users', state.user.uid), { agentName })
        .catch((error) => console.error("Failed to update agent name in Firestore:", error));
    }
  },
  SET_SENSE(state, sense) {
    state.sense = sense;
    saveStateToLocalStorage(state);

    if (state.user && state.user.uid) {
      updateDoc(doc(getFirestore(), 'users', state.user.uid), { sense: state.sense })
        .catch((error) => console.error("Failed to update sense in Firestore:", error));
    }
  },
  SET_ERROR(state, error) {
    state.error = error;
    saveStateToLocalStorage(state);
  },
  SET_PURCHASED_DOSSIERS(state, dossiers) {
    state.purchasedDossiers = dossiers;
    saveStateToLocalStorage(state);
  },
  SET_IN_PROGRESS_DOSSIERS(state, dossiers) {
    state.inProgressDossiers = dossiers;
    saveStateToLocalStorage(state);
  },
  SET_COMPLETED_DOSSIERS(state, dossiers) {
    console.log("Mutation SET_COMPLETED_DOSSIERS:", dossiers);
    state.completedDossiers = dossiers;
    saveStateToLocalStorage(state);
  },
  LOGOUT(state) {
    state.user = null;
    state.agentName = '';
    state.firstLogin = false;
    state.sense = 0;
    state.guest = false;
    state.purchasedDossiers = [];
    state.inProgressDossiers = [];
    state.completedDossiers = [];
    localStorage.removeItem('vuex'); // Clear persisted state on logout
  },
  SET_GUEST(state, isGuest) {
    state.guest = isGuest;
    saveStateToLocalStorage(state);
  }
};

const actions = {
  async updatePaperclips({ commit, state }, newPaperclipCount) {
    commit('SET_PAPERCLIPS', newPaperclipCount);

    if (state.user && state.user.uid) {
      try {
        await updateDoc(doc(getFirestore(), 'users', state.user.uid), { paperclips: newPaperclipCount });
      } catch (error) {
        console.error("Failed to update paperclips in Firestore:", error);
      }
    }
  },

  async usePaperclip({ commit, state }) {
    if (state.paperclips > 0) {
      const newPaperclipCount = state.paperclips - 1;
      commit('USE_PAPERCLIP', newPaperclipCount); // Commit mutation to update the state

      if (state.user && state.user.uid) {
        try {
          // Update Firestore with the new paperclip count
          const userRef = doc(getFirestore(), 'users', state.user.uid);
          await updateDoc(userRef, { paperclips: newPaperclipCount });
        } catch (error) {
          console.error('Failed to update paperclips in Firestore:', error);
          throw new Error('Firestore update failed');
        }
      }
    } else {
      throw new Error('No paperclips left');
    }
  },

  async saveDossierProgress({ commit, state }, { categoryId, dossierId, progress }) {
    if (state.user && state.user.uid) {
      const db = getFirestore();
      const userRef = doc(db, 'users', state.user.uid);

      try {
        const userDoc = await getDoc(userRef);
        const userData = userDoc.exists() ? userDoc.data() : {};
        const currentInProgress = Array.isArray(userData.inProgressDossiers) ? userData.inProgressDossiers : [];

        // Find the dossier index if it exists
        const dossierIndex = currentInProgress.findIndex(dossier =>
          dossier.category === categoryId && dossier.dossier === dossierId
        );

        if (dossierIndex === -1) {
          currentInProgress.push({
            category: categoryId,
            dossier: dossierId,
            progress
          });
        } else {
          currentInProgress[dossierIndex].progress = progress;
        }

        await updateDoc(userRef, { inProgressDossiers: currentInProgress });
        commit('SET_IN_PROGRESS_DOSSIERS', currentInProgress);
      } catch (error) {
        console.error("Failed to sync inProgressDossiers:", error);
      }
    }
  },
  async purchaseDossier({ commit, state }, { category, dossier }) {
    if (state.sense >= 100) {
      const updatedPurchasedDossiers = [...state.purchasedDossiers, { category, dossier }];
      commit('SET_PURCHASED_DOSSIERS', updatedPurchasedDossiers);
      commit('SET_SENSE', state.sense - 100); // Deduct 100 sense points

      if (state.user && state.user.uid) {
        try {
          const db = getFirestore();
          const userRef = doc(db, 'users', state.user.uid);
          await updateDoc(userRef, {
            purchasedDossiers: updatedPurchasedDossiers,
            sense: state.sense - 100
          });
        } catch (error) {
          console.error("Failed to purchase dossier:", error);
        }
      }
    } else {
      throw new Error("Not enough sense to purchase dossier");
    }
  },
  async logTransaction({ state }, transaction) {
    if (state.user && state.user.uid) {
      try {
        const db = getFirestore();
        const transactionsRef = collection(db, 'transactions'); // Assume 'paypalTransactions' as the collection
        await addDoc(transactionsRef, transaction);
      } catch (error) {
        console.error("Failed to log transaction:", error);
      }
    }
  },
  // Action to clear progress on dossier completion
  async clearDossierProgress({ commit, state }, { categoryId, dossierId }) {
    if (state.user && state.user.uid) {
      const db = getFirestore();
      const userRef = doc(db, 'users', state.user.uid);

      try {
        const userDoc = await getDoc(userRef);
        const userData = userDoc.exists() ? userDoc.data() : {};
        const updatedInProgress = (userData.inProgressDossiers || []).filter(dossier =>
          !(dossier.category === categoryId && dossier.dossier === dossierId)
        );

        await updateDoc(userRef, { inProgressDossiers: updatedInProgress });
        commit('SET_IN_PROGRESS_DOSSIERS', updatedInProgress);
      } catch (error) {
        console.error("Failed to clear inProgressDossiers:", error);
      }
    }
  },

  async login({ commit }, { email, password }) {
    commit('SET_ERROR', null);
    try {
      const { user, userData } = await login(email, password);
      commit('SET_USER', user);
      commit('SET_FIRST_LOGIN', userData.firstLogin);
      commit('SET_SENSE', userData.sense);

      commit('SET_PURCHASED_DOSSIERS', userData.purchasedDossiers || [
        { category: 'INTRO', dossier: 'First Dossier - Intro to CTG' }
      ]);
      commit('SET_COMPLETED_DOSSIERS', userData.completedDossiers || []);
      commit('SET_IN_PROGRESS_DOSSIERS', userData.inProgressDossiers || []);
    } catch (error) {
      commit('SET_ERROR', error.message);
    }
  },

  async register({ commit }, { email, password, promoAmount = 0 }) {
    try {
      const { user, firstLogin } = await register(email, password);
      commit('SET_USER', user);
      commit('SET_FIRST_LOGIN', true);

      // Add promo amount to user's sense
      commit('SET_SENSE', promoAmount);

      const defaultDossier = [{ category: 'INTRO', dossier: 'First Dossier - Intro to Conspiracy Trivia' }];
      console.log(Array.isArray(defaultDossier));
      console.log('Default dossier before writing:', defaultDossier);

      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);

      await setDoc(userRef, {
        email,
        sense: promoAmount,
        firstLogin: true,
        purchasedDossiers: defaultDossier,
        inProgressDossiers: [],
        completedDossiers: []
      });

      commit('SET_PURCHASED_DOSSIERS', defaultDossier);
    } catch (error) {
      commit('SET_ERROR', error.message);
    }
  },


  async saveAgentName({ commit, state }, agentName) {
    commit('SET_AGENT_NAME', agentName);
    if (state.user && state.user.uid) {
      try {
        await updateDoc(doc(getFirestore(), 'users', state.user.uid), { agentName });
      } catch (error) {
        console.error("Failed to update agent name in Firestore:", error);
      }
    }
  },

  async setFirstLogin({ commit, state }, firstLogin) {
    commit('SET_FIRST_LOGIN', firstLogin);
    if (state.user && state.user.uid) {
      try {
        await updateDoc(doc(getFirestore(), 'users', state.user.uid), { firstLogin });
      } catch (error) {
        console.error("Failed to update firstLogin flag in Firestore:", error);
      }
    }
  },

  async updateSensePoints({ commit, state }, points) {
    const newSense = state.sense + points;
    commit('SET_SENSE', newSense);
    if (state.user && state.user.uid) {
      try {
        await updateDoc(doc(getFirestore(), 'users', state.user.uid), { sense: newSense });
      } catch (error) {
        console.error("Failed to update sense in Firestore:", error);
      }
    }
  },

  async markDossierCompleted({ commit, state }, { categoryId, dossierId, correctAnswers, totalQuestions }) {
    if (state.user && state.user.uid) {
      const db = getFirestore();
      const userRef = doc(db, 'users', state.user.uid);

      try {
        const userDoc = await getDoc(userRef);
        const userData = userDoc.exists() ? userDoc.data() : {};
        const currentCompletedDossiers = Array.isArray(userData.completedDossiers) ? userData.completedDossiers : [];

        // Find the dossier index if it exists
        const dossierIndex = currentCompletedDossiers.findIndex(dossier =>
          dossier.category === categoryId && dossier.dossier === dossierId
        );

        if (dossierIndex === -1) {
          // If dossier is not completed yet, add a new entry
          currentCompletedDossiers.push({
            category: categoryId,
            dossier: dossierId,
            attempts: 1,
            finalScore: `${correctAnswers}/${totalQuestions}`
          });
        } else {
          // If dossier is already completed, update the existing entry
          currentCompletedDossiers[dossierIndex].attempts += 1;
          currentCompletedDossiers[dossierIndex].finalScore = `${correctAnswers}/${totalQuestions}`;
        }

        // Sanitize the array and update Firestore
        const sanitizedCompletedDossiers = currentCompletedDossiers.filter(dossier =>
          dossier && dossier.category && dossier.dossier && dossier.attempts !== undefined && dossier.finalScore
        );

        await updateDoc(userRef, { completedDossiers: sanitizedCompletedDossiers });
        commit('SET_COMPLETED_DOSSIERS', sanitizedCompletedDossiers);
      } catch (error) {
        console.error("Failed to sync completedDossiers:", error);
      }
    }
  },

  logout({ commit }) {
    commit('LOGOUT');
  }
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  isGuest: (state) => state.guest,
  user: (state) => state.user,
  agentName: (state) => state.agentName,
  firstLogin: (state) => state.firstLogin,
  sense: (state) => state.sense,
  error: (state) => state.error,
  purchasedDossiers: (state) => state.purchasedDossiers,
  inProgressDossiers: (state) => state.inProgressDossiers,
  completedDossiers: (state) => state.completedDossiers || [],
  paperclips: (state) => state.paperclips,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
