<template>
  <div class="paperclip-container">
    <!-- Paperclip Background -->
    <img src="@/assets/images/paperclips.webp" class="paperclip-background" alt="Paperclip Background" />

    <!-- Paperclip Info Modal -->
    <div class="paperclip-wrapper">
      <!-- Back Button (inside the white container) -->
      <button class="back-button" @click="goBack">Back</button>

      <h2 class="paperclip-title">Your Paperclips</h2>
      <p class="paperclip-total">Total Paperclips: {{ totalPaperclips }}</p>

      <!-- Purchase Input -->
      <div class="purchase-section">
        <label for="quantity">How many paperclips would you like to buy?</label>
        <input
          type="number"
          v-model.number="quantity"
          min="1"
          class="paperclip-input"
          id="quantity"
          @input="calculateCost"
        />
        <p class="cost">Total Sense Cost: {{ senseCost }} Sense</p>
        <button class="btn-purchase" @click="confirmPurchase">Purchase Paperclips</button>
      </div>
    </div>

    <!-- Purchase Modal for Insufficient Sense -->
    <div v-if="showPurchaseModal" class="modal show d-block" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Purchase Paperclips</h5>
            <button type="button" class="btn-close" @click="cancelPurchase"></button>
          </div>
          <div class="modal-body">
            <p>Not enough sense points. Would you like to buy more sense?</p>
          </div>
          <div class="modal-footer">
            <button @click="redirectToPayPal" class="btn btn-primary">Go to PayPal</button>
            <button @click="cancelPurchase" class="btn btn-secondary">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal for Successful Purchase -->
    <div v-if="showConfirmationModal" class="modal show d-block" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Purchase Successful</h5>
            <button type="button" class="btn-close" @click="closeConfirmationModal"></button>
          </div>
          <div class="modal-body">
            <p>You have successfully purchased {{ quantity }} paperclips!</p>
          </div>
          <div class="modal-footer">
            <button @click="closeConfirmationModal" class="btn btn-primary">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import { getFirestore, doc, updateDoc, onSnapshot } from 'firebase/firestore';

export default {
  name: 'PaperclipView',
  data() {
    return {
      totalPaperclips: 0,  // Track total paperclips
      quantity: 1,  // Number of paperclips to buy
      senseCost: 10,  // Calculated sense cost
      showPurchaseModal: false,  // Display modal if sense is insufficient
      showConfirmationModal: false,  // Display confirmation modal after successful purchase
    };
  },
  computed: {
    ...mapGetters('auth', ['sense']),  // Retrieve user's sense from Vuex
  },
  methods: {
    ...mapActions('auth', ['updateSensePoints', 'updatePaperclips']),

    calculateCost() {
      this.senseCost = this.quantity * 10;  // Each paperclip costs 10 sense points
    },

    async confirmPurchase() {
      // Check if the user has enough sense to purchase the desired number of paperclips
      if (this.sense >= this.senseCost) {
        // Deduct sense points from the user's account
        await this.$store.dispatch('auth/updateSensePoints', -this.senseCost);

        // Update the total paperclips
        const updatedPaperclips = this.totalPaperclips + this.quantity;
        await this.$store.dispatch('auth/updatePaperclips', updatedPaperclips);

        // Update the paperclips in Firestore
        const db = getFirestore();
        const userRef = doc(db, 'users', this.$store.getters['auth/user'].uid);
        await updateDoc(userRef, { paperclips: updatedPaperclips });

        // Show confirmation modal
        this.showConfirmationModal = true;

      } else {
        // If not enough sense, show the modal for insufficient sense
        this.showPurchaseModal = true;
      }
    },

    cancelPurchase() {
      this.showPurchaseModal = false;
    },

    closeConfirmationModal() {
      this.showConfirmationModal = false;
    },

    redirectToPayPal() {
      this.$router.push({ name: 'paypal' });
    },

    goBack() {
      this.$router.push('/desktop');
    },

    async fetchPaperclips() {
      // Listen for real-time updates to the paperclips in Firestore
      const db = getFirestore();
      const userRef = doc(db, 'users', this.$store.getters['auth/user'].uid);

      onSnapshot(userRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          this.totalPaperclips = docSnapshot.data().paperclips || 0;  // Initialize total paperclips
        }
      });
    },
  },
  async mounted() {
    this.fetchPaperclips();  // Fetch paperclips on mount with real-time updates
  },
};
</script>

<style scoped>
/* General reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Container */
.paperclip-container {
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow-y: auto; /* This allows vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Paperclip Background */
.paperclip-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  z-index: 0;
}

/* Paperclip Info Modal */
.paperclip-wrapper {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%); /* Center the container horizontally */
  width: 100vw; /* Ensure it doesn’t overflow the viewport */
  background-color: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  z-index: 10;
  overflow-y: auto;
}

/* Back Button (inside the white container) */
.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  z-index: 20;
}

.back-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.paperclip-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.paperclip-total {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Purchase Section */
.purchase-section {
  margin-top: 2rem;
}

.paperclip-input {
  width: 100px;
  padding: 0.5rem;
  margin: 1rem 0;
}

.cost {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.btn-purchase {
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-purchase:hover {
  background-color: #218838;
}

/* Modal */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1.5rem;
  z-index: 1000;
  text-align: center;
  width: 80%;
  max-width: 400px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
}

.modal-title {
  font-size: 1.25rem;
}

.modal-body {
  margin-top: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.modal-footer button {
  width: 48%;
}

.btn-close {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: black;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

</style>
