<template>
  <div class="donation-container">
    <button class="back-button" @click="$router.push('/home')">Back</button>
    <div class="donation-card">
      <h2 class="title">Make a Donation</h2>

      <!-- Donation Amount Section -->
      <div class="donation-amount-section">
        <label for="donationAmount" class="donation-amount-label">Donation Amount ($)</label>
        <input
          id="donationAmount"
          v-model.number="donationAmount"
          type="number"
          min="1"
          class="donation-amount-input"
          placeholder="Enter amount to donate"
        />
      </div>

      <div id="paypal-button-container" class="paypal-button-container"></div>
    </div>

    <!-- Confirmation Modal -->
    <div v-if="showConfirmation" class="confirmation-modal">
      <div class="modal-content">
        <h3>Donation Successful</h3>
        <p><strong>Amount Donated:</strong> ${{ donationAmount }}</p>
        <p><strong>Transaction ID:</strong> {{ transactionDetails.id }}</p>
        <button class="btn-close" @click="closeConfirmation">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  data() {
    return {
      donationAmount: null,
      paypalLoaded: false,
      clientId: '',
      showConfirmation: false,
      transactionDetails: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['logTransaction']),
    async fetchPayPalClientId() {
      try {
        const db = getFirestore();
        const testCredentialsRef = doc(db, 'paypal', 'testCredentials');
        const credentialsDoc = await getDoc(testCredentialsRef);
        if (credentialsDoc.exists()) {
          this.clientId = credentialsDoc.data().clientId;
          this.loadPayPalScript();
        } else {
          console.error("PayPal test credentials not found.");
        }
      } catch (error) {
        console.error("Failed to fetch PayPal client ID:", error);
      }
    },
    loadPayPalScript() {
      if (this.paypalLoaded || !this.clientId) return;
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${this.clientId}`;
      script.addEventListener('load', this.initializePayPal);
      document.body.appendChild(script);
      this.paypalLoaded = true;
    },
    initializePayPal() {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          if (!this.donationAmount || this.donationAmount <= 0) {
            alert('Please enter a valid donation amount.');
            return;
          }
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: this.donationAmount.toFixed(2)
              }
            }]
          });
        },
        onApprove: async (data, actions) => {
          const details = await actions.order.capture();
          this.processDonation(details);
        },
        onError: (err) => {
          console.error('PayPal Error:', err);
          alert('Something went wrong with the donation.');
        }
      }).render('#paypal-button-container');
    },
    async processDonation(details) {
      await this.logTransaction({
        userId: this.user.uid,
        amount: this.donationAmount,
        timestamp: new Date(),
        transactionId: details.id,
        status: details.status
      });

      this.transactionDetails = details;
      this.showConfirmation = true;
    },
    closeConfirmation() {
      this.showConfirmation = false;
      this.transactionDetails = null;
      this.$router.push('/home');
    }
  },
  async mounted() {
    await this.fetchPayPalClientId();
  }
};
</script>

<style scoped>
.donation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem 1rem;
  background-color: #f4f4f9;
  overflow-y: auto;
  box-sizing: border-box;
}

.donation-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  max-height: 90vh;
  padding: 2rem;
  text-align: center;
  box-sizing: border-box;
  overflow-y: auto;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.donation-amount-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;
}

.donation-amount-label {
  font-size: 1rem;
  color: #333;
}

.donation-amount-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.modal-content p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.btn-close {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-close:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .donation-card {
    padding: 1.5rem;
    margin-top: 1rem;
    max-height: 80vh;
  }

  .donation-amount-input {
    font-size: 1rem;
    padding: 0.65rem;
  }
}
</style>
