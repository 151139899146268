<template>
  <div class="drawer-container">
    <!-- Back Button -->
    <button class="back-button" @click="goBack">
      <i class="fa fa-arrow-left"></i> Back
    </button>

    <img src="@/assets/images/drawer.webp" class="drawer-image" alt="Drawer Image" />

    <!-- Binder Button -->
    <button class="binder-button" @click="goToFolderPage"></button>

    <!-- Paper Clips Button -->
    <button class="paperclips-button" @click="handlePaperClipsClick"></button>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'DrawerView',
  methods: {
    goBack() {
      this.$router.go(-1); // Go back to the previous route
    },
    goToFolderPage() {
      this.$router.push('/folder'); // Navigate to the folder page
    },
    handlePaperClipsClick() {
      this.$router.push('/paperclips');// Placeholder for the paper clips action
    }
  },
  mounted() {
    // Lock viewport by preventing scrolling
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100vw';
    document.body.style.height = '100vh';
  },
  beforeDestroy() {
    // Restore scroll behavior when leaving the view
    document.body.style.overflow = '';
    document.body.style.position = '';
    document.body.style.width = '';
    document.body.style.height = '';
  }
};
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevents scrolling */
}

body {
  background-color: #000; /* Set background color to black in case image doesn't fully cover */
}

.drawer-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.drawer-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transform: scale(1);
  transition: transform 2s ease;
}

/* Back button in the top-left corner */
.back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: black;
  color: white;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  font-size: 1rem;
}

.back-button i {
  margin-right: 0.5rem;
}

/* Button for the binder */
.binder-button {
  position: absolute;
  top: 30%; /* Adjust this value to place it over the binder */
  left: 30%; /* Adjust this value to place it over the binder */
  width: 28%; /* Adjust to match the size of the binder */
  height: 40%; /* Adjust to match the size of the binder */
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.binder-button:hover {
  transform: scale(1.1);
  border-color: white;
}

/* Button for the paper clips */
.paperclips-button {
  position: absolute;
  top: 31%; /* Adjust this value to place it over the paper clips */
  left: 60%; /* Adjust this value to place it over the paper clips */
  width: 15%; /* Adjust to match the size of the paper clips */
  height: 15%; /* Adjust to match the size of the paper clips */
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.paperclips-button:hover {
  transform: scale(1.1);
  border-color: white;
}
</style>
