<template>
  <div class="paypal-container">
    <button class="back-button" @click="this.$router.go(-1);">Back</button>
    <div class="payment-card">
      <h2 class="title">Buy Cents</h2>
      <div class="amount-selection">
        <button
          @click="selectAmount(1)"
          :class="['btn', selectedAmount === 1 ? 'btn-selected' : 'btn-default']">
          $1 for 100 Cents
        </button>
        <button
          @click="selectAmount(3)"
          :class="['btn', selectedAmount === 3 ? 'btn-selected' : 'btn-default']">
          $3 for 400 Cents
        </button>
        <button
          @click="selectAmount(5)"
          :class="['btn', selectedAmount === 5 ? 'btn-selected' : 'btn-default']">
          $5 for 700 Cents
        </button>
        <button
          @click="selectAmount(10)"
          :class="['btn', selectedAmount === 10 ? 'btn-selected' : 'btn-default']">
          $10 for 1500 Cents
        </button>
      </div>

      <!-- Promo Code Section -->
<!--      <div class="promo-code-section">-->
<!--        <label for="promoCode" class="promo-code-label">Promo Code</label>-->
<!--        <input-->
<!--          id="promoCode"-->
<!--          v-model="promoCode"-->
<!--          @input="applyPromoCode"-->
<!--          class="promo-code-input"-->
<!--          placeholder="Enter promo code"-->
<!--        />-->
<!--        <p v-if="discount > 0" class="discount-info">Discount Applied: {{ discount * 100 }}%</p>-->
<!--      </div>-->

      <div id="paypal-button-container" class="paypal-button-container"></div>
    </div>

    <!-- Confirmation Modal -->
    <div v-if="showConfirmation" class="confirmation-modal">
      <div class="modal-content">
        <h3>Transaction Successful</h3>
        <p><strong>Amount:</strong> ${{ finalAmount }}</p>
        <p><strong>Sense Purchased:</strong> {{ calculateSensePurchased }}</p>
        <p><strong>Transaction ID:</strong> {{ transactionDetails.id }}</p>
        <button class="btn-close" @click="closeConfirmation">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default {
  data() {
    return {
      selectedAmount: null,
      paypalLoaded: false,
      clientId: '',
      promoCode: '',
      discount: 0,
      finalAmount: null,
      showConfirmation: false,
      transactionDetails: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'sense']),
    calculateSensePurchased() {
      switch (this.selectedAmount) {
        case 1:
          return 100;
        case 3:
          return 400;
        case 5:
          return 700;
        case 10:
          return 1500;
        default:
          return 0;
      }
    }
  },
  methods: {
    ...mapActions('auth', ['updateSensePoints', 'logTransaction']),
    async fetchPayPalClientId() {
      try {
        const db = getFirestore();
        const testCredentialsRef = doc(db, 'paypal', 'prodCredentials'); //testCredentials for test
        const credentialsDoc = await getDoc(testCredentialsRef);
        if (credentialsDoc.exists()) {
          this.clientId = credentialsDoc.data().clientId;
          this.loadPayPalScript();
        } else {
          console.error("PayPal credentials not found.");
        }
      } catch (error) {
        console.error("Failed to fetch PayPal client ID:", error);
      }
    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.calculateFinalAmount();
    },
    async applyPromoCode() {
      if (!this.promoCode) {
        this.discount = 0;
        this.calculateFinalAmount();
        return;
      }

      try {
        const db = getFirestore();
        const promoRef = doc(db, 'promotionCodes', this.promoCode.trim().toLowerCase()); // Adjusting promo code input
        const promoDoc = await getDoc(promoRef);

        if (promoDoc.exists() && promoDoc.data().active) {
          this.discount = promoDoc.data().discount; // Assuming the discount is stored as a decimal (e.g., 0.5 for 50%)
        } else {
          this.discount = 0; // No discount if the code is inactive or doesn't exist
        }
      } catch (error) {
        console.error("Error applying promo code:", error);
        this.discount = 0;
      }

      this.calculateFinalAmount();
    },
    calculateFinalAmount() {
      if (this.selectedAmount) {
        this.finalAmount = this.selectedAmount * (1 - this.discount);
      }
    },
    loadPayPalScript() {
      if (this.paypalLoaded || !this.clientId) return;
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${this.clientId}`;
      script.addEventListener('load', this.initializePayPal);
      document.body.appendChild(script);
      this.paypalLoaded = true;
    },
    initializePayPal() {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          if (!this.selectedAmount) {
            alert('Please select an amount to continue.');
            return;
          }
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: this.finalAmount.toFixed(2)
              }
            }]
          });
        },
        onApprove: async (data, actions) => {
          const details = await actions.order.capture();
          this.processPayment(details);
        },
        onError: (err) => {
          console.error('PayPal Error:', err);
          alert('Something went wrong with the transaction.');
        }
      }).render('#paypal-button-container');
    },
    async processPayment(details) {
      const purchasedSense = this.calculateSensePurchased;
      await this.updateSensePoints(purchasedSense);
      await this.logTransaction({
        userId: this.user.uid,
        amount: this.finalAmount,
        sensePurchased: purchasedSense,
        timestamp: new Date(),
        transactionId: details.id,
        status: details.status
      });

      this.transactionDetails = details;
      this.showConfirmation = true;
    },
    closeConfirmation() {
      this.showConfirmation = false;
      this.transactionDetails = null;
      this.$router.go(-1);
    }
  },
  async mounted() {
    await this.fetchPayPalClientId();
  }
};
</script>

<style scoped>
.paypal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem 1rem;
  background-color: #f4f4f9;
  overflow-y: auto;
  box-sizing: border-box;
}

.payment-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  max-height: 90vh;
  padding: 2rem;
  text-align: center;
  box-sizing: border-box;
  overflow-y: auto;
}

.title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.amount-selection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.promo-code-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;
}

.promo-code-label {
  font-size: 1rem;
  color: #333;
}

.promo-code-input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 0.5rem;
  box-sizing: border-box;
}

.discount-info {
  font-size: 0.9rem;
  color: #28a745;
  margin-top: 0.5rem;
}

.btn {
  padding: 0.75rem;
  font-size: 1.1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-default {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  color: #333;
}

.btn-selected {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.confirmation-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.modal-content p {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

.btn-close {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-close:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .payment-card {
    padding: 1.5rem;
    margin-top: 1rem;
    max-height: 80vh;
  }

  .btn {
    font-size: 1rem;
    padding: 0.65rem;
  }
}
</style>
