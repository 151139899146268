<template>
  <div class="home-container">
    <!-- Account Section with Sense Display -->
    <div class="account-section" @click="toggleAccountDropdown">
      <i class="fa fa-user-circle account-icon"></i>
      <div class="account-info">
        <span class="user-name">{{ userName }}</span>
        <span class="sense-balance">Cents: {{ userSense }}</span>
      </div>
    </div>
    <div v-if="dropdownOpen" class="dropdown-menu account-menu">
      <button class="dropdown-item" @click="handleBuySense">Buy Cents</button>
<!--      <button class="dropdown-item" @click="handlePaymentInfo">Payment Information</button>-->
      <button class="dropdown-item" @click="handleLogout">Logout</button>
    </div>

    <!-- Key Icon Section -->
    <div class="key-icon-section" @click="toggleKeyDropdown">
      <img src="@/assets/images/logoKey.png" alt="Key Icon" class="key-icon" />
    </div>
    <div v-if="keyDropdownOpen" class="dropdown-menu key-menu">
<!--      <button class="dropdown-item" @click="handleAbout">About Conspiracy Trivia</button>-->
<!--      <button class="dropdown-item" @click="handleSources">Sources and Additional Information</button>-->
      <button class="dropdown-item" @click="handleDonation">Donate to Support Development</button>
      <button class="dropdown-item" @click="handlePromo">Promotional Codes</button>
      <button class="dropdown-item" @click="handleTermsOfUse">Terms of Use</button>
    </div>

    <!-- Desk Left Side (One large clickable area for drawers) -->
    <button class="desk-button desk-left" @click="handleDrawerClick('desk-left')"></button>

    <!-- Desk Right Side (One large clickable area for drawers) -->
    <button class="desk-button desk-right" @click="handleDrawerClick('desk-right')"></button>

    <!-- Computer Screen (Clickable) -->
    <button class="computer-button" @click="handleComputerClick"></button>

    <!-- Phone (Clickable) -->
    <button class="phone-button" @click="handlePhoneClick"></button>

    <!-- Filing Cabinets (Left and Right) -->
    <button class="filing-cabinet-button filing-cabinet-left" @click="handleFilingCabinetClick"></button>
    <button class="filing-cabinet-button filing-cabinet-right" @click="handleFilingCabinetClick"></button>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  data() {
    return {
      dropdownOpen: false, // Controls the account dropdown
      keyDropdownOpen: false, // Controls the key icon dropdown
      isZoomed: false // Track zoom state
    };
  },
  computed: {
    ...mapGetters('auth', ['agentName', 'sense']),
    userName() {
      return this.agentName || 'Agent';
    },
    userSense() {
      return this.sense || 0;
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    handleLogout() {
      this.logout();
      this.$router.push('/login');
    },
    handleBuySense() {
      this.$router.push('/paypal');
    },
    handleTermsOfUse() {
      this.$router.push('/termsofuseback');
    },
    handleDonation() {
      this.$router.push('/donation');
    },
    handlePromo() {
      this.$router.push('/promo');
    },
    handlePaymentInfo () {
     // alert('Redirect to Payment Information page.');
    },
    handleAbout() {
      //alert('About Conspiracy Trivia information.');
    },
    handleSources() {
      //alert('Sources and Additional Information.');
    },
    toggleAccountDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      this.keyDropdownOpen = false; // Close the key dropdown if it's open
    },
    toggleKeyDropdown() {
      this.keyDropdownOpen = !this.keyDropdownOpen;
      this.dropdownOpen = false; // Close the account dropdown if it's open
    },
    handleDrawerClick(position) {
      this.isZoomed = true;
      this.$router.push('/drawer');
    },
    handleComputerClick() {
      this.$router.push('/desktop');
    },
    handlePhoneClick() {
      alert('Phone clicked!');
    },
    handleFilingCabinetClick() {
      this.$router.push('/desktop'); // Navigate to DesktopView
    }
  }
};
</script>


<style scoped>
/* General reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home-container {
  height: 100vh;
  width: 100vw;
  background: url('@/assets/images/desk.webp') no-repeat center center;
  background-size: 100% 100%; /* Stretch the image to cover the entire viewport */
  position: relative;
  overflow: hidden;
  display: flex;
}

/* Account Section with Sense Display */
.account-section {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.account-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.account-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: bold;
}

.sense-balance {
  font-size: 0.9rem;
}

/* Key Icon Section */
.key-icon-section {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: 2px solid white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.key-icon-section i {
  font-size: 1.5rem;
}

/* Dropdown Menu Styles */
.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  z-index: 1000;
  min-width: 150px;
}

.account-menu {
  top: 3.5rem;
  left: 1rem;
}

.key-menu {
  top: 3.5rem;
  right: 1rem;
}

.dropdown-item {
  background-color: white;
  border: none;
  padding: 0.5rem 1rem;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.key-icon {
  width: 36px; /* Adjust size as needed */
  height: 36px;
  cursor: pointer;
}
/* Desk Left Side (One large button) */
.desk-button.desk-left {
  position: absolute;
  top: calc(71%); /* Adjusted for desk drawers */
  left: calc(35%);
  width: calc(11% + 2vw); /* Dynamic width using calc */
  height: calc(25% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
}

/* Desk Right Side (One large button) */
.desk-button.desk-right {
  position: absolute;
  top: calc(71%);
  right: calc(32.5%);
  width: calc(11% + 2vw); /* Dynamic width using calc */
  height: calc(25% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(50%, -50%);
}

/* Computer Screen Button */
.computer-button {
  position: absolute;
  top: calc(42%);
  left: calc(50.5%);
  width: calc(13% + 2vw); /* Dynamic width using calc */
  height: calc(20% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* Phone Button */
.phone-button {
  position: absolute;
  top: calc(50%);
  left: calc(66%);
  width: calc(8% + 1.5vw); /* Dynamic width using calc */
  height: calc(10% + 1.5vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.filing-cabinet-button.filing-cabinet-left {
  position: absolute;
  top: calc(60%); /* Adjust the position relative to the image */
  left: calc(16%);
  width: calc(15% + 1.5vw); /* Dynamic width using calc */
  height: calc(60% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

/* Right Filing Cabinet Button */
.filing-cabinet-button.filing-cabinet-right {
  position: absolute;
  top: calc(60%);
  right: calc(10.5%);
  width: calc(14% + 1.5vw); /* Dynamic width using calc */
  height: calc(60% + 2vh); /* Dynamic height using calc */
  background-color: white;
  border: none;
  opacity: 0;
  transform: translate(50%, -50%);
  cursor: pointer;
}
/* Media query adjustments for smaller screens */
@media (max-width: 400px) {
  .account-section {
    font-size: 0.8rem;
    padding: 0.4rem 0.7rem;
  }

  .key-icon-section {
    padding: 0.4rem 0.7rem;
  }

  .desk-button.desk-left,
  .desk-button.desk-right {
    width: calc(22% + 1vw);
    height: calc(38% + 1.5vh);
    top: calc(70%);
  }

  .computer-button {
    width: calc(21% + 1vw);
    height: calc(21% + 1.5vh);
    top: calc(40%);
  }

  .phone-button {
    width: calc(17% + 1.5vw);
    height: calc(12% + 1.5vh);
    top: calc(50%);
    left: calc(66%);
  }
}
</style>

